<template>
  <div>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="d-flex justify-content-between">
        <b-button class="next" variant="warning" @click="openModal">
          Add New
        </b-button>
        <b-form-group class="m-0">
          <b-input-group>
            <b-form-input
              v-model="keyword"
              placeholder="Search..."
              @change="$refs.table.refresh()"
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br />
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png" />
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchRole"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>

        <template #cell(status)="{ item }">
          {{ item.isActive ? 'Active' : 'Inactive' }}
        </template>

        <template #cell(action)="{ item }">
          <b-link class="mr-2" @click="goToEdit(item.id)">
            <fa-icon class="text-muted" icon="edit" />
          </b-link>
          <b-link
              class="text-secondary mr-2"
              @click="goToView(item.id)"
            >
              <fa-icon icon="eye" />
            </b-link>
          <b-link @click="goToDelete(item)">
            <fa-icon class="text-muted" icon="trash" />
          </b-link>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="$refs.table.refresh()"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="$refs.table.refresh()"
          />
          <span class="show-entries mr-auto">
            {{
              `Show ${
                totalRows === 0 ? 0 : (currentPage - 1) * perPage + 1
              } to ${
                totalRows > currentPage * perPage
                  ? currentPage * perPage
                  : totalRows
              } of ${totalRows} entries`
            }}
          </span>
        </div>
      </div>
    </b-card>
    <modal-role
      :is-edit="isEdit"
      :is-view="isView"
      :form="form"
      @reset="resetForm"
      @submit="submitRole"
      @update="updateRole"
    />
    <modal-delete-role
      :role-name="formDelete.name"
      @remove="removeRole"
    />
  </div>
</template>

<script>
import api from '@/api'
import ModalRole from './ModalRole.vue'
import ModalDeleteRole from './ModalDeleteRole.vue'
export default {
  components: {
    ModalRole,
    ModalDeleteRole
  },
  data: () => ({
    keyword: '',
    sortBy: '',
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      {
        key: 'no',
        label: 'No',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'name',
        label: 'Role',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: false
      },
      {
        key: 'status',
        label: 'Availibilty Status',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'action',
        label: 'Action',
        tdClass: 'text-center',
        thClass: 'text-center'
      }
    ],
    pageOptions: [10, 20, 50, 100],
    items: [],
    form: {
      id: null,
      name: null,
      isActive: false
    },
    formDelete: {},
    isEdit: false,
    isView: false
  }),
  methods: {
    async fetchRole() {
      const { data } = await api.role.list({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: `name@=*${this.keyword}`,
        sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage

      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    async openModal() {
      this.$bvModal.show('modal-role')
    },
    async goToEdit(id) {
      this.isEdit = true
      const { data } = await api.role.getRole(id)
      this.form = {
        ...data.data
      }

      this.$bvModal.show('modal-role')
    },
    async goToView(id) {
      this.isView = true
      const { data } = await api.role.getRole(id)
      this.form = {
        ...data.data
      }

      this.$bvModal.show('modal-role')
    },
    goToDelete(item) {
      this.formDelete = {
        ...item
      }
      this.$bvModal.show('modal-delete-role')
    },
    async submitRole(permission) {
      try {
        const submitForm = {
          name: this.form.name,
          isActive: this.form.isActive,
          menuPermissions: permission
        }
        await api.role.submitRole(submitForm)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save role', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        setTimeout(() => {
          this.$bvModal.hide('modal-role')
          this.$refs.table.refresh()
        }, 2000)
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async removeRole() {
      try {
        await api.role.deleteRole(this.formDelete.id)
        this.$nextTick(() => {
          this.$bvToast.toast(`Success delete role ${this.formDelete.name}`, {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    async updateRole(permission) {
      try {
        const submitForm = {
          id: this.form.id,
          name: this.form.name,
          isActive: this.form.isActive,
          menuPermissions: permission
        }
        await api.role.updateRole(submitForm.id, submitForm)
        this.$nextTick(() => {
          this.$bvModal.hide('modal-role')
          this.$bvToast.toast('Success update role', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
          this.$refs.table.refresh()
          setTimeout(() => {
            this.isEdit = false
          }, 1500)
        })
      } catch (error) {
        this.$nextTick(() => {
          this.$bvToast.toast(error.message ? error.message.messageEng : error.data.messageEng, {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    resetForm() {
      this.isView = this.$options.data().isView
      this.isEdit = this.$options.data().isEdit
      this.form = this.$options.data().form
    }
  }
}
</script>

<style lang="scss" scoped>
.col,
.col-2,
.col-3,
.col-6 {
  padding: 0 16px;
}
</style>
