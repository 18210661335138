<template>
  <b-modal
    id="modal-role"
    :title="`${isEdit ? 'Edit' : 'Add New'} Role`"
    hide-footer
    centered
    size="xl"
    @hidden="resetModal"
  >
    <validation-observer ref="form" slim>
      <b-row>
        <b-col cols="4">
          <validation-provider
            name="Role Name"
            rules="required|max:100"
            v-slot="{ errors }"
          >
            <b-form-group label="Role Name *" :invalid-feedback="errors[0]">
              <b-form-input
                placeholder="Enter role name"
                v-model="form.name"
                trim
                :class="{ 'is-invalid': errors.length }"
                :disabled="isView"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <div class="d-flex justify-content-between">
          <span>Avaibility Status</span>
          <b-form-group>
            <b-form-checkbox
              v-model="form.isActive"
              :disabled="isView"
              switch
            />
          </b-form-group>
          </div>
        </b-col>
        <b-col class="text-right">

        </b-col>
      </b-row>
      <h6 class="mt-3 font-weight-bold">Permission</h6>
      <b-form-checkbox
        class="mb-2"
        v-model="isMasterData"
        @change="fetchPermissions(true)"
        :disabled="isView"
      >
        Master Data
      </b-form-checkbox>
      <hr>
      <b-row
        class="d-flex justify-content-between"
        v-for="item in permissions"
        :key="item.id"
      >
        <b-col cols="2">
          <b-form-checkbox
            v-model="item.status"
            @change="changeMenuPermissions(item.status, item.id)"
            :disabled="isView"
          >
            {{ item.name }}
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="item.permissions.canView"
            @change="changeChildPermissions(item.id)"
            :disabled="isView"
          >
            View
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="item.permissions.canCreate"
            @change="changeChildPermissions(item.id)"
            :disabled="isView"
          >
            Add
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="item.permissions.canDelete"
            @change="changeChildPermissions(item.id)"
            :disabled="isView"
          >
            Delete
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="item.permissions.canUpdate"
            @change="changeChildPermissions(item.id)"
            :disabled="isView"
          >
            Edit
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="item.permissions.canDownload"
            @change="changeChildPermissions(item.id)"
            :disabled="isView"
          >
            Download
          </b-form-checkbox>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="item.permissions.isActive"
            @change="changeChildPermissions(item.id)"
            :disabled="isView"
          >
            isActive
          </b-form-checkbox>
        </b-col>
      </b-row>
      <div v-if="!isView" class="mt-3 d-flex justify-content-center">
        <b-button
          class="text-warning mt-2"
          variant="outline-warning"
          @click="cancel"
          block
        >
          Cancel
        </b-button>
        <b-button
          class="next ml-3"
          variant="warning"
          @click="submit"
          block
        >
          {{ isEdit ? 'Update' : 'Save' }}
        </b-button>
      </div>
      <div v-else class="mt-4"></div>
    </validation-observer>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false
    },
    isView: {
      type: Boolean,
      default: () => false
    },
    form: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    isBusy: false,
    isMasterData: false,
    permissions: []
  }),

  created() {
    this.fetchPermissions()
  },

  watch: {
    'form.groupMenuPermissions'(value) {
      if (value) {
        value.map(v => {
          this.permissions.filter(d => d.id === v.menuId)
            .map(i => {
              i.permissions = {
                id: v.id,
                menuId: v.menuId,
                groupId: v.groupId,
                canCreate: v.canCreate,
                canDelete: v.canDelete,
                canDownload: v.canDownload,
                canSubmit: v.canSubmit,
                canUpdate: v.canUpdate,
                canView: v.canView,
                isActive: v.isActive
              }
              if (i.permissions.canView && i.permissions.canCreate && i.permissions.canDelete &&
                i.permissions.canUpdate && i.permissions.canDownload && i.permissions.isActive) {
                i.status = true
              }
            })
        })
      }
    }
  },

  methods: {
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return false
      }

      if (this.isEdit) {
        const submitPermissions = []
        this.permissions.map((v) => {
          if (v.permissions.canCreate || v.permissions.canDelete || v.permissions.canView || v.permissions.canUpdate ||
           v.permissions.canSubmit || v.permissions.isActive || v.permissions.canDownload) {
            submitPermissions.push({
              id: v.permissions.id ? v.permissions.id : null,
              menuId: v.permissions.menuId ? v.permissions.menuId : v.id,
              groupId: v.permissions.groupId ? v.permissions.groupId : this.form.id,
              canView: v.permissions.canView,
              canUpdate: v.permissions.canUpdate,
              canCreate: v.permissions.canCreate,
              canDelete: v.permissions.canDelete,
              canSubmit: v.permissions.canSubmit,
              canDownload: v.permissions.canDownload,
              isActive: v.permissions.isActive
            })
          }
        })
        this.$emit('update', submitPermissions)
      } else {
        const submitPermissions = this.permissions.map(v => {
          return {
            menuId: v.id,
            canView: v.permissions.canView,
            canUpdate: v.permissions.canUpdate,
            canCreate: v.permissions.canCreate,
            canDelete: v.permissions.canDelete,
            canSubmit: v.permissions.canSubmit,
            canDownload: v.permissions.canDownload,
            isActive: v.permissions.isActive
          }
        })
        this.$emit('submit', submitPermissions)
      }
    },
    async fetchPermissions() {
      const { data } = await api.role.permissions(this.isMasterData)
      this.permissions = data.data
      this.permissions.map(v => {
        v.status = this.isMasterData
      })
    },
    changeMenuPermissions(status, id) {
      this.permissions.filter(v => v.id === id).map(i => {
        for (const property in i.permissions) {
          if (property !== 'id' && property !== 'menuId' && property !== 'groupId') {
            i.permissions[property] = status
          }
        }
      })
    },
    changeChildPermissions(id) {
      const data = this.permissions.map(i => {
        if (i.id === id) {
          if (i.permissions.canView && i.permissions.canCreate && i.permissions.canDelete &&
          i.permissions.canUpdate && i.permissions.canDownload && i.permissions.isActive) {
            i.status = true
          } else {
            i.status = false
          }
        }
        return i
      })

      this.permissions = data
    },
    resetModal() {
      this.$emit('reset')
      this.fetchPermissions()
    },
    cancel() {
      this.$bvModal.hide('modal-role')
    }
  }
}
</script>

<style lang="scss" scoped>
.col, .col-2, .col-4 {
  padding: 2px 14px;
}
hr {
  margin-top: 0;
  opacity: 0.2;
  border: 1px solid #000000;
}
</style>
